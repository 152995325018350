
  
  .map-container {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }
  
  .search {
    margin-left: 20px;
    border-radius:5px;
    border: none;
    color:#fff;
    background-color:#2B2A2F;
  }
  .mapboxgl-marker {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border:1px solid gray;
    background-color:lightblue;
  }
  
  .input {
    margin-left: 20px;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    padding: 2px 8px;
  }
  
  .coords-container {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 100;
    margin: 12px;
    border-radius: 4px;
  }
  
  .marker{
    background-color: #0cebeb;
    color: #000000;
    background-size: cover;
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 50%;
  }
  